import type {
  SetLayout,
  SetAudio,
  SetFullScreen,
  SetVideo,
  MarkScreenShared,
  MarkScreenShareLoading,
} from './actions/controls'
import type {ToggleSidePanel, OpenSidePanel} from './actions/side-panel'
import type {State} from './reducers'
import type {
  AddParticipant,
  GetSession,
  LeaveSession,
  SetDemoSession,
  SetJoinError,
  MarkJoinedOnAnotherDevice,
  ResetJoinedOnAnotherDevice,
} from './actions/session'
import {
  PushNotification,
  ScheduleJoinedNotification,
  FlushJoinedNotifications,
  CancelJoinedNotification,
  EnableJoinNotifications,
  DisableJoinNotifications,
} from './actions/notifications'
import type {
  AddPendingChatMessage,
  DeleteChatMessage,
  FetchChatHistory,
  ReceiveChatMessage,
  RemovePendingChatMessage,
} from './actions/chat'
import type {
  ListParticipants,
  SetParticipantActivity,
  SetParticipantStatus,
  UpdateParticipant,
} from './actions/participants'
import type {ReceiveReaction, ListReactions, SendReaction} from './actions/reactions'
import type {SetEnvironment} from './actions/environment'
import type {NavigateToMainPage} from './actions/navigation'
import type {ShowModal, HideModal} from './actions/modals'

export interface WidgetProps extends State {
  id: string
  authorization: string
  baseUrl: string
  actions: Actions
}

export interface Actions {
  getSession: GetSession
  leaveSession: LeaveSession
  addPendingChatMessage: AddPendingChatMessage
  removePendingChatMessage: RemovePendingChatMessage
  receiveChatMessage: ReceiveChatMessage
  deleteChatMessage: DeleteChatMessage
  fetchChatHistory: FetchChatHistory
  addParticipant: AddParticipant
  setVideo: SetVideo
  setAudio: SetAudio
  setFullScreen: SetFullScreen
  toggleSidePanel: ToggleSidePanel
  openSidePanel: OpenSidePanel
  setLayout: SetLayout
  listParticipants: ListParticipants
  updateParticipant: UpdateParticipant
  setParticipantActivity: SetParticipantActivity
  setParticipantStatus: SetParticipantStatus
  pushNotification: PushNotification
  receiveReaction: ReceiveReaction
  listReactions: ListReactions
  sendReaction: SendReaction
  setEnvironment: SetEnvironment
  navigateToMainPage: NavigateToMainPage
  setDemoSession: SetDemoSession
  markScreenShared: MarkScreenShared
  markScreenShareLoading: MarkScreenShareLoading
  markJoinedOnAnotherDevice: MarkJoinedOnAnotherDevice
  resetJoinedOnAnotherDevice: ResetJoinedOnAnotherDevice
  scheduleJoinedNotification: ScheduleJoinedNotification
  flushJoinedNotifications: FlushJoinedNotifications
  cancelJoinedNotification: CancelJoinedNotification
  enableJoinNotifications: EnableJoinNotifications
  disableJoinNotifications: DisableJoinNotifications
  setJoinError: SetJoinError
  showModal: ShowModal
  hideModal: HideModal
}

export interface GetCurrentComponentArgs {
  mobile: boolean
  connected: boolean
  ended: boolean
  leftSession: boolean
  forcedScreen: string
  participantBanned: boolean
  participantJoinedElsewhere: boolean
  rejoining: boolean
}

export enum ModalType {
  UNSUPPORTED_BROWSER,
  TEST_VIDEO_PARTICIPANT_LIMIT,
}
